import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";
import { showToastMessage } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    showToastMessage("error", action.payload.data.message);
    if(action.payload.status === 401){
      LocalStorage.clearStorage().then(() => {
        window.location.reload();
      });
    }    
  }
  return next(action);
};
