import { ProfileModel } from "../models/ProfileModel";
import { localStorageKeys } from "./Helper";

export class LocalStorage {
    static getLoggedInStatus() {
        let isLoggedIn = localStorage.getItem(localStorageKeys.isLoggedIn);
        return isLoggedIn !== null && isLoggedIn === "true";
    }
    static getSignatureStatus() {
        return localStorage.getItem("PendingSignatures") !== null;
    }
    static updateSignatureStatus(isPending: boolean){
        localStorage.setItem("PendingSignatures",`${isPending}`);        
    }
    static updateLoggedInStatus(isLoggedIn: boolean, token: string) {
        localStorage.setItem(localStorageKeys.isLoggedIn, `${isLoggedIn}`);
        localStorage.setItem(localStorageKeys.token, token);        
    }
    static getToken() {
        let token = localStorage.getItem(localStorageKeys.token);
        if (token === null) {
            token = "";
        }
        return token;
    }

    static GetProfileModel() {
        let profileModel = null;
        let profileString = localStorage.getItem(localStorageKeys.profile);
        if (profileString) {
            profileModel = JSON.parse(profileString) as ProfileModel;
        }
        return profileModel;
    }
    static async clearStorage() {
        await localStorage.clear();
    }
    static async getLocalStorageKeys() {
        return await Object.keys(localStorage);
    }

    static setCallBackURL(url: string){
        localStorage.setItem("callbackUrl", url);
    }

    static getCallBackURL(){
        return localStorage.getItem("callbackUrl");
    }

    static removeCallBackURL(){
        return localStorage.removeItem("callbackUrl")
    }
}