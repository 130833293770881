import {
  AlternateEmailRounded,
  Edit,
  Groups,
  Groups3,
  LocationCityRounded,
  LocationOnRounded,
  Numbers,
  PersonRounded,
  PhoneRounded,
} from "@mui/icons-material";
import {
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { ProfileModel } from "../../models/ProfileModel";

type ProfileDetailModel = {
  field: string;
  value: string;
  icon: any;
  showEdit?: boolean;
};

interface Props {
  profile: ProfileModel;
  showEditForm?(): void;
}

export const ViewProfile: React.FC<Props> = (props) => {
  let profile = props.profile;
  const profileDetails: ProfileDetailModel[] = [
    {
      field: "First Name",
      value: profile.firstName,
      icon: <PersonRounded />,
      showEdit: true,
    },
    {
      field: "Last Name",
      value: profile.lastName,
      icon: <PersonRounded />,
      showEdit: true,
    },
    {
      field: "Phone",
      value: profile.phone,
      icon: <PhoneRounded />,
      showEdit: true,
    },
    ...(profile.isPrimaryUser
      ? [
          {
            field: "Company",
            value: profile.companyName,
            icon: <Groups3 />,
          },
        ]
      : [
          {
            field: "Member Id",
            value: profile.memberId,
            icon: <Numbers />,
          },
          {
            field: "My Company",
            value: profile.subCompanyName,
            icon: <Groups />,
          },
          {
            field: "Main Company",
            value: profile.companyName,
            icon: <Groups3 />,
          },
        ]),
    {
      field: "Address",
      value: createAddressText(),
      icon: <LocationCityRounded />,
      showEdit: true,
    },
    { field: "Email", value: profile.email, icon: <AlternateEmailRounded /> },
    {
      field: "Country",
      value: profile.country.name,
      icon: <LocationOnRounded />,
    },
  ];

  function createAddressText() {
    let address;
    if (profile.address1) {
      address = profile.address1;
    }
    if (profile.address2) {
      if (address) {
        address += ", " + profile.address2;
      } else {
        address = profile.address2;
      }
    }
    if (profile.state) {
      if (address) {
        address += ", " + profile.state.stateName;
      } else {
        address = profile.state.stateName;
      }
    }
    return address ?? "N/A";
  }

  return (
    <List>
      {profileDetails.map((item, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <Icon>{item.icon}</Icon>
          </ListItemIcon>
          <ListItemText primary={item.field} secondary={item.value} />
          {item.showEdit && props.showEditForm && (
            <ListItemIcon>
              <IconButton onClick={props.showEditForm}>
                <Edit />
              </IconButton>
            </ListItemIcon>
          )}
        </ListItem>
      ))}
    </List>
  );
};
