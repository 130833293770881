import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
  colors,
} from "@mui/material";
import React, { useState } from "react";
import { ControlledTextField } from "../shared/ControlledTextField";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getcompanyData,
  setcompanyData,
} from "../../reduxenv/Slices/CompanyDataSlice";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DoubleArrow, SaveRounded } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreditRequestFormSchema } from "../../schemas/CreditRequestSchema";
import { CreditRequestFormModel } from "../../models/CreditRequestFormModel";
import { localStorageKeys } from "../../common/Helper";
import { AppColors } from "../../common/AppColors";
import { FileUploadButton } from "../shared/FileUploadButton";
import dayjs from "dayjs";
import { FileModel } from "../../models/FileModel";
import { RegistrationStepIndexes } from "../../common/Enums";
import { setSuppliers } from "../../reduxenv/Slices/SupplierSlice";
import { setDirectors } from "../../reduxenv/Slices/DirectorsSlice";

interface Props {
  handleGoToNextStep(nextStepIndex: number): void;
  fromProfilePage?: boolean;
}

const CreditRequestForm: React.FC<Props> = (props) => {
  let isFromProfilePage = props.fromProfilePage;
  const companyData = useSelector(getcompanyData);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreditRequestFormModel>({
    resolver: yupResolver(CreditRequestFormSchema),
    defaultValues: companyData
      ? {
          expectedMonthlyPurchase: companyData.expectedMonthlyPurchase,
          creditLimit: companyData.creditLimit,
          yearEndDate: companyData.yearEndDate,
          isAudited: companyData.isAudited,
          type: companyData.type,
          isPublicCompany: companyData.isPublicCompany
        }
      : {
          expectedMonthlyPurchase: 0,
          creditLimit: 0,
          yearEndDate: "",
          isAudited: false,
          isPublicCompany: false,
          type: ""
        },
  });
  const [profitLossFile1, setProfitLossFile1] = useState<FileModel>();
  const [profitLossFile2, setProfitLossFile2] = useState<FileModel>();
  const [balanceSheetFile1, setBalanceSheetFile1] = useState<FileModel>();
  const [balanceSheetFile2, setBalanceSheetFile2] = useState<FileModel>();
  let profileLossStatementPaths =
    companyData && companyData.profitLossStatements;
  let balanceSheetPaths = companyData && companyData.balanceSheets;

  async function handleClickSkip() {
    submit();
  }

  async function submit(
    creditRequestFormModel: CreditRequestFormModel | string = ""
  ) {
    let nextStepIndex = RegistrationStepIndexes.Directors;
    if (creditRequestFormModel !== "") {
      let tempCreditRequest = creditRequestFormModel as CreditRequestFormModel;
      let company = { ...companyData };
      company.expectedMonthlyPurchase =
        tempCreditRequest.expectedMonthlyPurchase;
      company.creditLimit = tempCreditRequest.creditLimit;
      company.yearEndDate = tempCreditRequest.yearEndDate;
      company.isAudited = tempCreditRequest.isAudited;
      company.type = tempCreditRequest.type;
      company.isPublicCompany = tempCreditRequest.isPublicCompany;

      company.profitLossStatements = [];
      if (profitLossFile1) {
        company.profitLossStatements.push(profitLossFile1.path);
      }
      if (profitLossFile2) {
        company.profitLossStatements.push(profitLossFile2.path);
      }
      if (company.profitLossStatements.length === 0) {
        company.profitLossStatements = profileLossStatementPaths;
      }

      company.balanceSheets = [];
      if (balanceSheetFile1) {
        company.balanceSheets.push(balanceSheetFile1?.path!);
      }
      if (balanceSheetFile2) {
        company.balanceSheets.push(balanceSheetFile2?.path!);
      }
      if (company.balanceSheets.length === 0) {
        company.balanceSheets = balanceSheetPaths;
      }

      await localStorage.setItem(
        localStorageKeys.company,
        JSON.stringify(company)
      );
      dispatch(setcompanyData(company));
      await localStorage.removeItem(localStorageKeys.suppliers);
    } else {
      await localStorage.setItem(localStorageKeys.suppliers, "");
      await localStorage.setItem(localStorageKeys.directors, "");
      dispatch(setSuppliers([]));
      dispatch(setDirectors([]));
      // Skipped Credit request, Go to T&C
      nextStepIndex = RegistrationStepIndexes.Terms;
    }
    // Setting "" to identify that user has completed the Credit Request step
    await localStorage.setItem(localStorageKeys.credits, "");
    props.handleGoToNextStep(nextStepIndex);
  }

  return (
    <Card
      sx={{ p: 2 }}
      variant="outlined"
      component={"form"}
      onSubmit={handleSubmit(submit)}
    >
      <CardContent>
        <Typography mt={2} variant="h4" color={AppColors.Blue}>
          Credit Request
          {!isFromProfilePage && (
            <Button
              onClick={handleClickSkip}
              color="warning"
              variant="outlined"
              sx={{
                display: "flex",
                ml: "auto",
                float: "right",
                fontWeight: "bold",
                color: colors.red[500],
              }}
              endIcon={<DoubleArrow />}
            >
              Skip
            </Button>
          )}
        </Typography>
        <Divider />
        <Grid container spacing={2} mt={1} justifyContent={"center"}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="expectedMonthlyPurchase"
              label="Anticipated Monthly Purchases with us*"
              error={errors.expectedMonthlyPurchase}
              inputProps={{
                type: "number",
                placeholder: "0.0",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="type"
              label="Business Type/Category*"
              error={errors.type}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="isPublicCompany"
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="It's a Public Company"
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="creditLimit"
              label="Credit Limit Amount*"
              error={errors.creditLimit}
              inputProps={{
                type: "number",
                placeholder: "0.0",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="yearEndDate"
              render={({ field }) => (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(field.value)}
                      onChange={(date) => field.onChange(date)}
                      label={"Company Year-End Date*"}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
            {errors.yearEndDate && (
              <FormHelperText error>
                {errors.yearEndDate.message}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="isAudited"
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="Are your year-end financial statements audited?"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Upload Last 2 Years Profit & Loss Statement</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            {profileLossStatementPaths && profileLossStatementPaths[0] ? (
              <FormHelperText>{profileLossStatementPaths[0]}</FormHelperText>
            ) : (
              <FileUploadButton
                name="profileLossFile1"
                label="Upload File 1"
                selectedFile={profitLossFile1!}
                setSelectedFile={setProfitLossFile1}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {profileLossStatementPaths && profileLossStatementPaths[1] ? (
              <FormHelperText>{profileLossStatementPaths[1]}</FormHelperText>
            ) : (
              <FileUploadButton
                name="profileLossFile2"
                label="Upload File 2"
                selectedFile={profitLossFile2!}
                setSelectedFile={setProfitLossFile2}
              />
            )}
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography>Upload Last 2 Years Balance Statement</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            {balanceSheetPaths && balanceSheetPaths[0] ? (
              <FormHelperText>{balanceSheetPaths[0]}</FormHelperText>
            ) : (
              <FileUploadButton
                name="balanceSheetFile1"
                label="Upload File 1"
                selectedFile={balanceSheetFile1!}
                setSelectedFile={setBalanceSheetFile1}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {balanceSheetPaths && balanceSheetPaths[1] ? (
              <FormHelperText>{balanceSheetPaths[1]}</FormHelperText>
            ) : (
              <FileUploadButton
                name="balanceSheetFile2"
                label="Upload File 2"
                selectedFile={balanceSheetFile2!}
                setSelectedFile={setBalanceSheetFile2}
              />
            )}
          </Grid>
          <Button
            type="submit"
            sx={{ mt: 2, pt: 1, pl: 4, pr: 4, ml: "auto", display: "flex" }}
            color="success"
            variant="contained"
            endIcon={<SaveRounded />}
          >
            {isFromProfilePage ? "Continue" : "SAVE"}
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreditRequestForm;
