import {
  ListRounded,
  LocalShipping,
  LocationOnRounded,
  Numbers,
  PhoneRounded,
  PriceChangeRounded,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { OrderModel, paymentStatuses } from "../../models/OrderModel";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import { RefundHistoryList } from "./RefundHistoryList";
import { useGetInvoicePdfQuery } from "../../services/MyOrderServices";

interface Props {
  styles: any;
  order: OrderModel;
}

export const OrderDetails: React.FC<Props> = (props) => {
  let styles = props.styles;
  let order = props.order;
  let deliveryDetails =
    order.orderDeliveryDetails && order.orderDeliveryDetails.length > 0
      ? order.orderDeliveryDetails[0]
      : undefined;
  const { formatCurrency } = useFormatCurrency();
  const { data: invoiceBlob, isFetching } = useGetInvoicePdfQuery(order.id);
  console.log(deliveryDetails);
  

  async function handleClickViewInvoice() {
    if (invoiceBlob) {
      const url = window.URL.createObjectURL(invoiceBlob);
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    }
  }

  return (
    <DialogContent>
      <DialogTitle>
        Order Details <span style={styles.orderNo}>#{order.id}</span>
      </DialogTitle>
      <DialogContent>
        <List>
          {deliveryDetails && (
            <ListItem>
              <ListItemIcon>
                <LocalShipping />
              </ListItemIcon>
              <ListItemText
                primary={"Delivery Details"}
                secondary={
                  <>
                    {deliveryDetails.firstName
                      ? deliveryDetails.firstName +
                        " " +
                        deliveryDetails.lastName
                      : deliveryDetails.contactName}
                    <br />
                    {deliveryDetails.contactPhone}
                    <br />
                    {deliveryDetails.deliveryAddress}
                  </>
                }
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemIcon>
              <LocationOnRounded />
            </ListItemIcon>
            <ListItemText
              primary={"Billing Address"}
              secondary={order.billingAddress}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneRounded />
            </ListItemIcon>
            <ListItemText primary={"Phone"} secondary={order.contactPhone} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PriceChangeRounded />
            </ListItemIcon>
            <ListItemText
              primary={"Total"}
              secondary={formatCurrency(order.grandtotal)}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Numbers />
            </ListItemIcon>
            <ListItemText
              primary={"PO Number"}
              secondary={order.poNumber ?? "N/A"}
            />
          </ListItem>
        </List>
        {order.paymentStatus === paymentStatuses.refunded && (
          <RefundHistoryList orderId={order.id} />
        )}
        <Button
          type="button"
          startIcon={!isFetching && <ListRounded />}
          fullWidth
          variant="contained"
          disabled={isFetching || !invoiceBlob}
          onClick={handleClickViewInvoice}
        >
          {isFetching ? <CircularProgress size={25} /> : "View Invoice"}
        </Button>
      </DialogContent>
    </DialogContent>
  );
};
