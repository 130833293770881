import { colors } from "@mui/material";

export const AppColors = {
    Blue: "#3E485D",
    LightGray: "#F5F5F5",
    MainColor: "#00a88e",
    LightColor: colors.blue[50],
    White: colors.common.white,
    Black: colors.common.black,
    DarkGray: "#444",
    DarkBrown: "#383838F7",
    Green: "#00a88e"
}