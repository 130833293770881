import React, { useEffect, useState } from "react";
import { SelectOptionModel } from "../../models/SelectOptionModel";
import { StateModel } from "../../models/StateModel";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { snakeToCamel } from "../../common/Helper";
import { useGetAllStatesQuery } from "../../services/AuthServices";

interface Props {
  countryId: number;
  getSelectedState(state: StateModel): void;
  selectedStateId: number;
  error: any;
}

export const StatesSelect: React.FC<Props> = (props) => {
  let selectedStateId = props.selectedStateId;
  const { data: statesResponse } = useGetAllStatesQuery({
    countryId: props.countryId,
  });
  const [stateModels, setStateModels] = useState<StateModel[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectOptionModel[]>([]);

  useEffect(() => {
    if (statesResponse && statesResponse.status === "success") {
      let states = snakeToCamel(statesResponse.response) as StateModel[];
      let options: SelectOptionModel[] = [];
      states.forEach((state) => {
        let option: SelectOptionModel = {
          option: state.stateName,
          value: state.id,
        };
        options.push(option);
      });
      setStateOptions(options);
      setStateModels(states);
      if (selectedStateId) {
        let tempSelectedState = states.find((x) => x.id === selectedStateId);
        props.getSelectedState(tempSelectedState!);
      }
    }
  }, [statesResponse]);

  function handleSelectState(event: SelectChangeEvent) {
    let states = [...stateModels];
    let tempSelectedState = states.find(
      (x) => x.id === parseInt(event.target.value)
    );
    if (tempSelectedState) {
      props.getSelectedState(tempSelectedState);
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel>State*</InputLabel>
      <Select
        label={"State*"}
        onChange={handleSelectState}
        value={
          stateOptions.length > 0 && props.selectedStateId
            ? props.selectedStateId.toString()
            : ""
        }
      >
        {stateOptions.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.option}
          </MenuItem>
        ))}
      </Select>
      {props.error && (
        <FormHelperText error>{props.error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
