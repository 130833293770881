import * as yup from "yup";

export const OtherResellersSchema = yup.object().shape({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  subCompanyName: yup.string().nullable().label("Company Name"),
  memberId: yup.string().nullable().label("Member Id"),
  email: yup.string().email().required().label("Email Address"),
  phone: yup.string().required().label("Phone"),
});
