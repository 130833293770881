import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Avatar, Box, Grid, Icon, Typography, colors } from "@mui/material";
import { AppColors } from "../common/AppColors";
import { Images } from "../common/Images";
import { CheckOutlined } from "@mui/icons-material";

export const RegistrationSucceed: React.FC = () => {
  return (
    <>
      <Header />
      <Grid container spacing={2} justifyContent={"center"}>
        <Box
          height={"50vh"}
          width={"100%"}
          sx={{
            backgroundImage: `url(${Images.kasperskyWallpaper})`,
            backgroundSize: "81% 190%",
          }}
          position={"relative"}
          display={"flex"}
          alignItems={"center"}
        >
          <Box
            bgcolor={AppColors.White}
            sx={{
              margin: "auto",
              left: "0",
              right: "0",
              backgroundColor: "#fff",
              color: "#fff",
              textAlign: "center",
              position: "absolute",
              width: { xs: "90%", md: "60vw" },
              maxWidth: "700px",
              height: "35vh",
              opacity: "0.5",
              background: "rgba(255, 255, 255, 0.24)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(255, 255, 255, 0.3)",
            }}
          ></Box>
          <Box
            position={"absolute"}
            color={AppColors.White}
            textAlign={"center"}
            width={{ xs: "90%", md: "60vw" }}
            maxWidth={"700px"}
            left={0}
            right={0}
            margin={"auto"}
          >
            <Avatar sx={{bgcolor: colors.green[500], margin: "auto"}}>
              <Icon>
                <CheckOutlined />
              </Icon>
            </Avatar>
            <Typography variant="h4">Thank you for submitting</Typography>
            <Typography variant="subtitle1">
              We're currently reviewing your application
            </Typography>
            <Typography variant="subtitle1">
              We'll send you a mail once your application has been approved.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Footer />
    </>
  );
};
