import { colors } from "@mui/material";
import { StyleModel } from "../models/StyleModel";
import { Images } from "../common/Images";

export const auth: StyleModel = {
  mobile: {
    wallpaper: {
      width: "100%",
      height: "80vh",
      position: "absolute",
      zIndex: "-1",
      right: "0",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    contentBox: {
      overflow: "auto",
      width: "100%",
      height: "86vh",
    },
    loginContainer: {
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    currentStepWrapper: {
      padding: "4vw 4.28vw",
    },
    continueButton: {
      backgroundColor: colors.green[500],
      color: colors.common.white,
      fontWeight: "bold",
      padding: "1.4vw 2.41vw",
      "&:hover": {
        backgroundColor: colors.green[400],
      },
    },
    stepsDrawer: {
      position: "relative",
      zIndex: "1",
    },
    stepsList: {
      pt: "20vw"
    }
  },
  desktop: {
    wallpaper: {
      width: "100%",
      backgroundImage: `url('${Images.kasperskyWallpaper}')`,
      backgroundSize: "100%",
      backgroundPosition: "100% 47%",
    },
    loginContainer: {
      marginRight: "auto",
      marginLeft: "7.29vw",
    },
    contentBox: {
      width: "76vw",
      marginLeft: "auto",
      wide: {
        width: "100%",
      },
    },
    currentStepWrapper: {
      padding: "1vw 10vw",
    },
    continueButton: {
      padding: "0.52vw 2.08vw",
      margin: "0.78vw auto 0 auto",
      display: "flex",
    },
    stepsList: {
      pt: "6vw",
    },
  },
};
