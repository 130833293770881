import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import {
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  Pagination,
  Paper,
  Typography,
  colors,
  useMediaQuery,
} from "@mui/material";
import { StyleContext } from "../context/StyleContextProvider";
import { OrderCard } from "../components/Orders/OrderCard";
import { OrderDetails } from "../components/Orders/OrderDetails";
import { OrderModel } from "../models/OrderModel";
import {
  appTitle,
  getMonthAndDay,
  scrollToTop,
  snakeToCamel,
} from "../common/Helper";
import { useGetMyOrdersQuery } from "../services/MyOrderServices";
import { DigitalKeysList } from "../components/Orders/DigitalKeysList";
import { Helmet } from "react-helmet-async";
import { useFormatCurrency } from "../hooks/useFormatCurrency";
import { AppColors } from "../common/AppColors";
import SalesGraph from "../components/Explore/SalesGraph";
import {
  useGetAllOrdersQuery,
  useGetTotalDueQuery,
} from "../services/DashboardServices";
import { FilterPanel } from "../components/Orders/FilterPanel";
import { OrdersLoader } from "../components/Loaders/OrdersLoader";
import { useSelector } from "react-redux";
import { getProfile } from "../reduxenv/Slices/ProfileSlice";

export const OrdersPage: React.FC = () => {
  const profile = useSelector(getProfile);
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("orders")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const today = new Date();
  const { data: allOrdersResponse, isLoading } = useGetMyOrdersQuery();
  const [orderList, setOrderList] = useState<OrderModel[]>();
  const [filteredOrderList, setFilteredOrderList] = useState<OrderModel[]>();
  const [clickedOrder, setClickedOrder] = useState<OrderModel>();
  const [page, setPage] = useState(1);
  const [showDigitalKeysFor, setShowDigitalKeysFor] = useState<{
    orderId: number;
    productId: number;
  }>();
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setMonth(today.getMonth() - 1))
  );
  const [toDate, setToDate] = useState(today);
  const [paymentStatus, setPaymentStatus] = useState("all");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [byResellerId, setByResellerId] = useState<number>();

  const { data: ordersResponse } = useGetAllOrdersQuery();
  const { data: totalDue } = useGetTotalDueQuery();
  const [currentOrderStatus, setCurrentOrderStatus] = useState("-");
  const [salesGraphData, setsalesGraphData] = useState([]);
  const { formatCurrency } = useFormatCurrency();
  const ordersPerPage = 10;
  const [orderResellers, setOrderResellers] = useState<
    {
      id: number;
      subCompanyName: string;
    }[]
  >([]);

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("orders"));
    scrollToTop();
  }, [isMobile]);

  useEffect(() => {
    if (allOrdersResponse && allOrdersResponse.status === "success") {
      let allOrders = snakeToCamel(allOrdersResponse.response) as OrderModel[];
      // add order resellers for filterpanel
      if (profile.isPrimaryUser) {
        let tempOrderResellers: { id: number; subCompanyName: string }[] = [];
        allOrders
          .filter((y) => y.resellerId !== profile.id)
          .forEach((x) => {
            const exists = tempOrderResellers.some(
              (reseller) => reseller.id === x.resellerId
            );
            if (!exists) {
              tempOrderResellers.push({
                id: x.resellerId,
                subCompanyName: x.reseller.subCompanyName,
              });
            }
          });
        setOrderResellers(tempOrderResellers);
      }
      setOrderList(allOrders);
      setFilteredOrderList(allOrders);
    }
  }, [allOrdersResponse]);

  useEffect(() => {
    if (ordersResponse) {
      let allOrders = snakeToCamel(ordersResponse.response) as OrderModel[];
      if (allOrders.length > 0) {
        setCurrentOrderStatus(allOrders[0].paymentStatus);
        // Reverse it for Graph usage
        let reversed = [...allOrders].reverse();
        let salesData: any = [];
        reversed.forEach((order) => {
          let tempSaleData = {
            name: getMonthAndDay(order.datetime),
            total: order.grandtotal,
          };
          salesData.push(tempSaleData);
        });
        setsalesGraphData(salesData);
      }
    }
  }, [ordersResponse]);

  useEffect(() => {
    if (orderList && fromDate <= toDate) {
      let tempOrders = [...orderList!];
      let filteredOrders = tempOrders.filter(
        (x) =>
          new Date(x.datetime) >= fromDate &&
          new Date(x.datetime) <= toDate &&
          (x.paymentStatus === paymentStatus.toUpperCase() ||
            paymentStatus === "all") &&
          (x.products.some((product) =>
            product.name
              .toLowerCase()
              .includes(searchKeyword.trim().toLowerCase())
          ) ||
            searchKeyword.trim() === "") &&
          (x.resellerId === byResellerId ||
            !byResellerId ||
            isNaN(byResellerId))
      );
      setFilteredOrderList(filteredOrders);
      setPage(1);
    }
  }, [orderList, fromDate, toDate, paymentStatus, searchKeyword, byResellerId]);

  function showOrderDetails(order: OrderModel) {
    setClickedOrder(order);
  }

  function handleChangePage(_event: React.ChangeEvent<unknown>, index: number) {
    setPage(index);
  }

  function handleShowDigitalKeys(orderId: number, productId: number) {
    setShowDigitalKeysFor({
      orderId: orderId,
      productId: productId,
    });
  }

  return (
    <>
      <Helmet>
        <title>{appTitle} - My Orders</title>
      </Helmet>
      <Header />
      <Container component={"div"} maxWidth={"xl"}>
        <Card sx={styles.wrapper} variant="outlined">
          <CardContent>
            <Grid container spacing={4} justifyContent={"center"}>
              <Grid item xs={12} lg={4}>
                <SalesGraph graphData={salesGraphData} />
                <Paper
                  sx={{
                    p: 1,
                    bgcolor: colors.green[500],
                    mt: 1,
                    mb: 1,
                    textAlign: "center",
                  }}
                  className="center-content"
                >
                  <div>
                    <Typography color={AppColors.White} variant="subtitle2">
                      Ongoing Order Status
                    </Typography>
                    <Typography
                      fontWeight={"bold"}
                      variant="h4"
                      color={colors.yellow[500]}
                    >
                      {currentOrderStatus}
                    </Typography>
                  </div>
                </Paper>
                <Paper
                  sx={{
                    p: 1,
                    bgcolor: colors.blue[500],
                    textAlign: "center",
                  }}
                  className="center-content"
                >
                  <div>
                    <Typography color={AppColors.White} variant="subtitle2">
                      Total Due Amount
                    </Typography>
                    <Typography
                      fontWeight={"bold"}
                      variant="h4"
                      color={colors.yellow[500]}
                    >
                      {formatCurrency(totalDue?.response)}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <FilterPanel
                    fromDate={fromDate}
                    toDate={toDate}
                    paymentStatus={paymentStatus}
                    searchKeyword={searchKeyword}
                    resellerId={byResellerId}
                    resellers={orderResellers}
                    setPaymentStatus={setPaymentStatus}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    setSearchKeyword={setSearchKeyword}
                    setResellerId={setByResellerId}
                  />
                  <Grid
                    item
                    xs={11.8}
                    overflow={"auto"}
                    height={"65vh"}
                    pl={0.2}
                    mt={1}
                    className={
                      !isLoading && filteredOrderList?.length === 0
                        ? "center-content"
                        : ""
                    }
                  >
                    {isLoading || !filteredOrderList ? (
                      <OrdersLoader />
                    ) : (
                      <>
                        {filteredOrderList.length > 0 ? (
                          <>
                            {filteredOrderList!
                              .slice(
                                (page - 1) * ordersPerPage,
                                (page - 1) * ordersPerPage + ordersPerPage
                              )
                              .map((order, index) => (
                                <OrderCard
                                  key={index}
                                  order={order}
                                  clickCard={showOrderDetails}
                                  styles={styles.card}
                                  showDigitalKeysList={handleShowDigitalKeys}
                                  isPrimaryUser={profile.isPrimaryUser}
                                />
                              ))}
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={styles.noOrders}>
                              {"There's no orders to show!"}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                  {!!filteredOrderList && filteredOrderList.length > 0 && (
                    <Grid item xs={12}>
                      <Pagination
                        count={Math.ceil(
                          (filteredOrderList?.length ?? 0) / ordersPerPage
                        )}
                        page={page}
                        onChange={handleChangePage}
                        sx={styles.pagination}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Dialog
        fullWidth
        open={!!clickedOrder}
        onClose={() => {
          setClickedOrder(undefined);
        }}
      >
        {clickedOrder && (
          <OrderDetails order={clickedOrder!} styles={styles.details} />
        )}
      </Dialog>
      <Dialog
        fullWidth
        open={!!showDigitalKeysFor}
        onClose={() => {
          setShowDigitalKeysFor(undefined);
        }}
      >
        {showDigitalKeysFor && (
          <DigitalKeysList
            orderId={showDigitalKeysFor.orderId}
            productId={showDigitalKeysFor.productId}
          />
        )}
      </Dialog>
      <Footer />
    </>
  );
};
