import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";
import { EndUserModel } from "../models/EndUserModel";

export const ordersAPI = createApi({
  reducerPath: "ordersAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL,
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMyOrders: builder.query<ResponseModel, void>({
      query: () => "/orders/all",
      keepUnusedDataFor: 0.0001,
    }),
    getAllDigitalKeys: builder.query<
      any,
      { orderId: number; productId: number }
    >({
      query: (params) => `/orders/keys/${params.orderId}/${params.productId}`,
      keepUnusedDataFor: 0.0001,
    }),
    getAllRefundHistory: builder.query<ResponseModel, any>({
      query: (orderId: number) => "/payments/refunds/" + orderId,
    }),
    getEndUserByDigitalKeyId: builder.query<ResponseModel, number>({
      query: (digital_key_id) => "/orders/end-user/id/" + digital_key_id,
      keepUnusedDataFor: 0.0001,
    }),
    assignEndUser: builder.mutation<ResponseModel, EndUserModel>({
      query: (endUser) => ({
        url: "orders/end-user/assign",
        method: "POST",
        body: {
          data: endUser,
          reminderTypeIds: endUser.end_user_reminder_types,
        },
      }),
    }),
    editEndUser: builder.mutation<ResponseModel, any>({
      query: (endUser: EndUserModel) => ({
        url: "orders/end-user/edit/" + endUser.id,
        method: "PUT",
        body: {
          data: endUser,
          reminderTypeIds: endUser.end_user_reminder_types,
        },
      }),
    }),
    removeEndUser: builder.mutation<ResponseModel, number>({
      query: (endUserId) => ({
        url: "orders/end-user/remove/" + endUserId,
        method: "DELETE",
        body: {},
      }),
    }),
    getAllReminderTypes: builder.query<ResponseModel, void>({
      query: () => "/orders/end-user/reminder-types",
    }),
    getInvoicePdf: builder.query<File, number>({
      query: (orderId) => ({
        url: "/orders/invoice-pdf/" + orderId,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetMyOrdersQuery,
  useGetAllDigitalKeysQuery,
  useGetAllRefundHistoryQuery,
  useGetEndUserByDigitalKeyIdQuery,
  useEditEndUserMutation,
  useRemoveEndUserMutation,
  useGetAllReminderTypesQuery,
  useAssignEndUserMutation,
  useGetInvoicePdfQuery,
} = ordersAPI;
