import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { OtherResellerModel } from "../../models/OtherResellerModel";
import { OtherResellerCard } from "./OtherResellerCard";
import { AddRounded } from "@mui/icons-material";
import { AppColors } from "../../common/AppColors";
import { AddEditOtherResellerForm } from "./AddEditOtherResellerForm";
import {
  useDeleteOtherResellerMutation,
  useGetAllResellersQuery,
} from "../../services/ProfileServices";
import { ResponseModel } from "../../models/ResponseModel";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import useConfirm from "../../hooks/useConfirm";
import { snakeToCamel } from "../../common/Helper";

export const ManageOtherResellers: React.FC = () => {
  const dispatch = useDispatch();
  const { data: resellersResponse, refetch: getOtherResellers } =
    useGetAllResellersQuery();
  const [deleteOtherReseller] = useDeleteOtherResellerMutation();
  const [otherResellers, setOtherResellers] = useState<OtherResellerModel[]>(
    []
  );
  const [isShowResellerForm, setIsShowResellerForm] = useState(false);
  const [selectedReseller, setSelectedReseller] =
    useState<OtherResellerModel>();
  const [DeleteResellerAlert, confirmDeleteReseller] = useConfirm(
    "Warning!",
    "Are you sure do you want delete this Reseller?"
  );

  useEffect(() => {
    if (resellersResponse && resellersResponse.status === "success") {
      let tempResellers = snakeToCamel(
        resellersResponse.response
      ) as OtherResellerModel[];
      setOtherResellers(tempResellers);
    }
  }, [resellersResponse]);

  function handleClickAddNewReseller() {
    setSelectedReseller(undefined);
    setIsShowResellerForm(true);
  }

  async function removeReseller(resellerId: number, index: number) {
    let deleteConfirmed = await confirmDeleteReseller();
    if (deleteConfirmed) {
      dispatch(setIsLoading(true));
      deleteOtherReseller(resellerId)
        .then((response: any) => {
          let responseModel = response.data as ResponseModel;
          if (responseModel.status === "success") {
            let allResellers = [...otherResellers];
            allResellers.splice(index, 1);
            setOtherResellers(allResellers);
            dispatch(setIsLoading(false));
          }
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }

  function handleAddReseller() {
    getOtherResellers();
    setIsShowResellerForm(false);
  }

  function handleClickEdit(reseller: OtherResellerModel) {
    setSelectedReseller(reseller);
    setIsShowResellerForm(true);
  }

  return (
    <>
      <DialogTitle
        variant="h5"
        color={AppColors.MainColor}
        fontWeight={"bold"}
        textAlign={"center"}
      >
        Manage Other Resellers
      </DialogTitle>
      <DialogContent>
        <Divider />
        <List>
          {otherResellers.map((reseller, index) => (
            <OtherResellerCard
              key={index}
              index={index}
              reseller={reseller}
              removeReseller={removeReseller}
              handleClickEdit={() => handleClickEdit(reseller)}
            />
          ))}
          <Grid
            item
            lg={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton onClick={handleClickAddNewReseller}>
              <AddRounded />
            </IconButton>
          </Grid>
        </List>

        {otherResellers.length === 0 && (
          <Typography
            textAlign={"center"}
            color={AppColors.DarkGray}
            variant="subtitle1"
          >
            Click '+' to Add a Reseller
          </Typography>
        )}
        <Dialog
          open={isShowResellerForm}
          fullWidth
          onClose={() => {
            setIsShowResellerForm(false);
          }}
        >
          <AddEditOtherResellerForm
            reseller={selectedReseller}
            handleAddReseller={handleAddReseller}
          />
        </Dialog>
      </DialogContent>
      <DeleteResellerAlert />
    </>
  );
};
