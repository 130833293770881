import { useDispatch, useSelector } from "react-redux";
import { EditProfileFormModel } from "../models/EditProfileFormModel";
import { getProfile, setProfile } from "../reduxenv/Slices/ProfileSlice";
import { localStorageKeys } from "../common/Helper";
import { StateModel } from "../models/StateModel";
import { ResponseModel } from "../models/ResponseModel";
import { usePutProfileMutation } from "../services/ProfileServices";

export const useProfileUpdate = () => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const [putProfile] = usePutProfileMutation();

  const updateProfile = (
    profileFormModel: EditProfileFormModel,
    state: StateModel
  ) => {
    putProfile(profileFormModel)
      .then((response: any) => {
        let responseModel = response.data as ResponseModel;
        if (responseModel.status === "success") {
          let updatedProfile = { ...profile };
          updatedProfile.firstName = profileFormModel.firstName;
          updatedProfile.lastName = profileFormModel.lastName;
          updatedProfile.phone = profileFormModel.phone;
          updatedProfile.address1 = profileFormModel.address1;
          updatedProfile.address2 = profileFormModel.address2;
          updatedProfile.state = state;
          localStorage.setItem(
            localStorageKeys.profile,
            JSON.stringify(updatedProfile)
          );
          dispatch(setProfile(updatedProfile));
        }
      })
      .catch(() => {});
  };
  return { updateProfile };
};
