import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import {
  Backdrop,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import RegisterPage from "./pages/RegisterPage";
import { StyleContextProvider } from "./context/StyleContextProvider";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./reduxenv/Store";
import ErrorPage from "./pages/ErrorPage";
import { AppColors } from "./common/AppColors";
import { LocalStorage } from "./common/Localstorage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ExplorePage } from "./pages/ExplorePage";
import ProductPage from "./pages/ProductPage";
import { getIsLoading } from "./reduxenv/Slices/AppSlice";
import ProfilePage from "./pages/ProfilePage";
import { useEffect } from "react";
import { ProfileModel } from "./models/ProfileModel";
import { localStorageKeys, snakeToCamel } from "./common/Helper";
import { setProfile, setToken } from "./reduxenv/Slices/ProfileSlice";
import { HelmetProvider } from "react-helmet-async";
import { useRefetchUserDataQuery } from "./services/AppServices";
import { CheckoutPage } from "./pages/CheckoutPage";
import { OrdersPage } from "./pages/OrdersPage";
import { SetNewPasswordPage } from "./pages/SetNewPasswordPage";
import { SearchResultsPage } from "./pages/SearchResultsPage";
import { ProductTrackingPage } from "./pages/ProductTrackingPage";
import { PolicyPage } from "./pages/PolicyPage";
import { ContactUsPage } from "./pages/ContactUsPage";
import { HomePage } from "./pages/HomePage";
import { WishlistPage } from "./pages/WishlistPage";
import { CategoryProductsPage } from "./pages/CategoryProductsPage";
import { RegistrationSucceed } from "./pages/RegistrationSucceedPage";
import { PendingSingaturePage } from "./pages/PendingSignaturePage";

const theme = createTheme({
  palette: {
    primary: {
      main: AppColors.MainColor,
    },
    secondary: {
      main: AppColors.LightColor,
      contrastText: AppColors.Black,
    },
  },
  typography: {
    fontFamily: '"Ubuntu"',
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    loader: checkBaseRoute,
    errorElement: <ErrorPage />,
  },
  {
    path: "/explore",
    loader: checkAuthRoute,
    element: <ExplorePage />,
  },
  {
    path: "/category-products/:categoryId",
    loader: checkAuthRoute,
    element: <CategoryProductsPage />,
  },
  {
    path: "/favourites",
    loader: checkAuthRoute,
    element: <WishlistPage />,
  },
  {
    path: "/home",
    loader: checkAuthRoute,
    element: <HomePage />,
  },
  {
    path: "/profile",
    loader: checkAuthRoute,
    element: <ProfilePage />,
  },
  {
    path: "/product/:id",
    loader: checkAuthRoute,
    element: <ProductPage />,
  },
  {
    path: "/search/:keyword",
    loader: checkAuthRoute,
    element: <SearchResultsPage />,
  },
  {
    path: "/checkout",
    loader: checkAuthRoute,
    element: <CheckoutPage />,
  },
  {
    path: "/orders",
    loader: checkAuthRoute,
    element: <OrdersPage />,
  },
  {
    path: "/track/:trackingId",
    element: <ProductTrackingPage />,
  },
  {
    path: "/policy/:type",
    element: <PolicyPage />,
  },
  {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "/auth",
    loader: checkLoginRoute,
    children: [
      { path: "/auth/login", element: <LoginPage /> },
      { path: "/auth/register", element: <RegisterPage /> },
      { path: "/auth/registration-succeed", element: <RegistrationSucceed /> },
    ],
  },
  {
    path: "/auth/set-new-password/:token",
    element: <SetNewPasswordPage />,
  },
  {
    path: "/profile/pending-signatures",
    loader: checkSignaturesRoute,
    element: <PendingSingaturePage />,
  },
]);

async function checkBaseRoute() {
  var hasUserSession = LocalStorage.getLoggedInStatus();
  var route = "/auth/login";
  if (hasUserSession) {
    var callbackUrl = LocalStorage.getCallBackURL();
    if (callbackUrl) {
      route = callbackUrl
      LocalStorage.removeCallBackURL();
    } else {
      route = "/home"
    }
  }
  return redirect(route);
}

async function checkLoginRoute() {
  var hasUserSession = LocalStorage.getLoggedInStatus();
  var route = null;
  if (hasUserSession) {
    var callbackUrl = LocalStorage.getCallBackURL();
    if (callbackUrl) {
      route = callbackUrl
      LocalStorage.removeCallBackURL();
    } else {
      route = "/home"
    }
  }
  return route && redirect(route);
}

async function checkAuthRoute() {
  var hasUserSession = LocalStorage.getLoggedInStatus();
  var route = null;
  if (!hasUserSession) {
    var currentUrl = window.location.pathname + window.location.search;
    LocalStorage.setCallBackURL(currentUrl);
    route = "/auth/login";
  }
  return route && redirect(route);
}

async function checkSignaturesRoute() {
  var route = null;
  if (!LocalStorage.getSignatureStatus()) {
    route = "/profile";
  }
  return route && redirect(route);
}

function AppWrapper() {
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { data: refetchResponse } = useRefetchUserDataQuery(undefined, {
    skip: !LocalStorage.getLoggedInStatus(),
  });

  useEffect(() => {
    if (refetchResponse && refetchResponse.status === "success") {
      let profile = snakeToCamel(refetchResponse.response) as ProfileModel;
      localStorage.setItem(localStorageKeys.profile, JSON.stringify(profile));
      LocalStorage.updateLoggedInStatus(true, profile.token);
      LocalStorage.updateSignatureStatus(profile.isPendingSignatures);
      dispatch(setToken(profile.token));
      dispatch(setProfile(profile));
    }
  }, [refetchResponse]);

  return (
    <StyleContextProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <Backdrop
            sx={{ color: AppColors.White, zIndex: 999999999 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <RouterProvider router={router} />
        </ThemeProvider>
      </HelmetProvider>
    </StyleContextProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  );
}

export default App;
