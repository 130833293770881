import { Typography } from "@mui/material";
import React from "react";
import { companyEmail } from "../../common/Helper";

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Typography>
        ICT Distribution Network regards customer privacy as an important part
        of our relationship with our customers. The following privacy policy
        applies to all ICT Distribution Network users, and conforms to Internet
        privacy standards. If you have questions or concerns regarding this
        statement, you should contact us at 1300 121 111 or email at
        info@ictdistribution.net
      </Typography>

      <Typography variant="h4" mt={4}>
        Collection of Information
      </Typography>
      <Typography variant="body1" mt={2}>
        In order to use the ICT Distribution Network website for purchases, we
        may require information from you in order to provide the best service
        possible.
        <br />
        All correspondence may also be collected and stored, particularly in
        regard to sales, support and accounts, including Email.
        <br />
        Any information collected by ICT Distribution Network is collected via
        correspondence from you or your company is strictly confidential and
        will remain only to ICT Distribution. This may be via the telephone,
        Email, mail, fax or directly through our website.
      </Typography>

      <Typography variant="h4" mt={4}>
        Use of Collection of Information
      </Typography>
      <Typography variant="body1" mt={2}>
        Any details collected from ICT Distribution Network customers is
        required in order to provide you with our products and/or services, and
        a high level of customer service. Correspondence is recorded in order to
        provide service references, and to assist in our staff development.
      </Typography>

      <Typography variant="h4" mt={4}>
        Storage of Collected Information
      </Typography>
      <Typography variant="body1" mt={2}>
        The security of your personal information is important to us. When you
        enter sensitive information (such as credit card numbers) on our
        website, we encrypt that information using secure socket layer
        technology (SSL). When Credit Card details are collected, we simply pass
        them on in order to be processed as required. We never permanently store
        complete Credit Card details.
        <br />
        We follow generally accepted industry standards to protect the personal
        information submitted to us, both during transmission and once we
        receive it. If you have any questions about security on our Website, you
        can email us at info@ictdistribution.net
      </Typography>

      <Typography variant="h4" mt={4}>
        Access to Collected Information
      </Typography>
      <Typography variant="body1" mt={2}>
        If your personally identifiable information changes, or if you no longer
        desire our service, you may correct, update, delete or deactivate it by
        emailing us at info@ictdistribution.net
      </Typography>

      <Typography variant="h4" mt={4}>
        Orders
      </Typography>
      <Typography variant="body1" mt={2}>
        f you purchase a product or service from us, we may request certain
        personally identifiable information from you. You may be required to
        provide contact information (such as name, Email, and postal address)
        and financial information (such as credit card number, expiration date).
        <br />
        We use this information for billing purposes and to fill your orders. If
        we have trouble processing an order, we will use this information to
        contact you.
      </Typography>

      <Typography variant="h4" mt={4}>
        Communications
      </Typography>
      <Typography variant="body1" mt={2}>
        ICT Distribution Network uses personally identifiable information for
        essential communications, such as Emails, accounts information, and
        critical service details. We may also use this information for other
        purposes, including some promotional emails. If at any time a customer
        wishes not to receive such correspondence, they can request to be
        removed from any mailing lists by emailing us at
        info@ictdistribution.net
        <br />
        You will be notified when your personal information is collected by any
        third party that is not our agent/service provider, so you can make an
        informed choice as to whether or not to share your information with that
        party.
      </Typography>

      <Typography variant="h4" mt={4}>
        Third Parties
      </Typography>
      <Typography variant="body1" mt={2}>
        ICT Distribution Network may at its discretion use other third parties
        to provide essential services on our site or for our business processes.
        We may share your details as necessary for the third party to provide
        that service.
        <br />
        These third parties are prohibited from using your personally
        identifiable information for any other purpose.
        <br />
        ICT Distribution Network does not share any information with third
        parties for any unknown or unrelated uses.
      </Typography>

      <Typography variant="h4" mt={4}>
        Legal
      </Typography>
      <Typography variant="body1" mt={2}>
        We reserve the right to disclose your personally identifiable
        information as required by law and when we believe that disclosure is
        necessary to protect our rights and/or comply with a judicial
        proceeding, court order, or legal process served on our website
      </Typography>

      <Typography variant="h4" mt={4}>
        Links
      </Typography>
      <Typography variant="body1" mt={2}>
        Links on the site to external entities are not covered within this
        policy. The terms and conditions set out in this privacy statement only
        cover the domain name of https://ictdistribution.net
      </Typography>

      <Typography variant="h4" mt={4}>
        Changes To This Privacy Policy
      </Typography>
      <Typography variant="body1" mt={2}>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
        <br />
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Typography>

      <Typography variant="h4" mt={4}>
        Contact Us
      </Typography>
      <Typography variant="body1" mt={2}>
        If you have any questions about this Privacy Policy, please contact us{" "}
        <a href={"mailto:" + companyEmail}>{companyEmail}</a>
      </Typography>
    </>
  );
};
