import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { Stack, IconButton, Button } from "@mui/material";
import React from "react";

interface Props {
  quantity: number;
  setQuantity(quantity: number): void;
  max: number;
}

export const QuantityButton: React.FC<Props> = (props) => {
  let quantity = props.quantity;

  function changeQuantity(add = false) {
    let tempQuantity = quantity;
    if (add) {
      tempQuantity = tempQuantity + 1;
    } else if (tempQuantity > 1) {
      tempQuantity = tempQuantity - 1;
    }
    props.setQuantity(tempQuantity);
  }

  return (
    <Stack
      direction={"row"}
      spacing={1}
      mt={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <IconButton disabled={quantity === 1} onClick={() => changeQuantity()}>
        <RemoveRounded />
      </IconButton>
      <Button variant="outlined">{quantity}</Button>
      <IconButton disabled={quantity === props.max} onClick={() => changeQuantity(true)}>
        <AddRounded />
      </IconButton>
    </Stack>
  );
};
