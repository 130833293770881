import React, { useContext, useEffect, useState } from "react";
import { StyleContext } from "../../context/StyleContextProvider";
import { Box, Button, Icon, IconButton, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfile } from "../../reduxenv/Slices/ProfileSlice";
import {
  ExploreOutlined,
  FavoriteBorder,
  HomeOutlined,
  LocalShippingOutlined,
  MenuRounded,
  PersonOutline,
} from "@mui/icons-material";
import { AppColors } from "../../common/AppColors";
import { CartDialog } from "../Cart/CartDialog";
import { NavigationDrawer } from "./NavigationDrawer";
import { SearchBar } from "../shared/SearchBar";
import { LocalStorage } from "../../common/Localstorage";

interface Props {
  handleClickMenu?(): void;
  searchQuery?: string;
  hideSearchBar?: boolean;
}

const Header: React.FC<Props> = (props) => {
  const isLoggedIn = LocalStorage.getLoggedInStatus();;
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState<any>(
    styleContext.getComponentStyle("header")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const navOptions = [
    { title: "Home", path: "/", icon: HomeOutlined },
    { title: "Explore", path: "/explore", icon: ExploreOutlined },
    { title: "My Orders", path: "/orders", icon: LocalShippingOutlined },
    { title: "Account", path: "/profile", icon: PersonOutline },
    { title: "Favourites", path: "/favourites", icon: FavoriteBorder },
  ];
  const [showNavDrawer, setShowNavDrawer] = useState(false);

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("header"));
  }, [isMobile]);

  function handleClickMenu() {
    if (props.handleClickMenu) {
      props.handleClickMenu();
    } else {
      setShowNavDrawer(true);
    }
  }

  return (
    <div style={styles.container}>
      <Link style={styles.logo} to={"/"}>
        <img style={styles.logo} src="/logo.png" alt="" />
      </Link>
      {isMobile && (isLoggedIn || props.handleClickMenu) && (
        <>
          <IconButton
            color="primary"
            sx={{ float: "right",}}
            onClick={handleClickMenu}
          >
            <MenuRounded />
          </IconButton>
          <NavigationDrawer
            show={showNavDrawer}
            setShow={setShowNavDrawer}
            navOptions={navOptions}
          />
        </>
      )}
      {isLoggedIn && (
        <>
          {!props.hideSearchBar && (
            <SearchBar searchQuery={props.searchQuery} styles={{
              background: AppColors.LightGray
            }} />
          )}
          <Box sx={styles.navbar}>
            {navOptions.map((option, index) => (
              <Link key={index} style={styles.navLink} to={option.path}>
                <Button
                  color="inherit"
                  sx={styles.navButton}
                  key={index}
                  endIcon={
                    <Icon sx={{ lineHeight: "0" }}>
                      {<option.icon fontSize="inherit" />}
                    </Icon>
                  }
                >
                  {option.title}
                </Button>
              </Link>
            ))}
            <CartDialog />
          </Box>
        </>
      )}
    </div>
  );
};

export default Header;
