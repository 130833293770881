import { Box, Button, colors, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { OrdersProductList } from "./OrdersProductList";
import { OrderModel, paymentStatusColors } from "../../models/OrderModel";
import { getFormatedDate } from "../../common/Helper";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";

interface Props {
  styles: any;
  clickCard(order: OrderModel): void;
  order: OrderModel;
  showDigitalKeysList(orderId: number, productId: number): void;
  isPrimaryUser: boolean;
}

export const OrderCard: React.FC<Props> = (props) => {
  let styles = props.styles;
  let order = props.order;
  const { formatCurrency } = useFormatCurrency();

  function handleClickViewDigitalKeys(productId: number) {
    props.showDigitalKeysList(order.id, productId);
  }

  return (
    <Grid item xs={12} sx={styles.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2.5}>
          <Typography sx={styles.total}>
            {"Total: " + formatCurrency(order.grandtotal)}
          </Typography>{" "}
          {order.tax && order.tax > 0 && (
            <Typography variant="caption">
              {order.tax.toFixed(2)} GST Included
            </Typography>
          )}
          {order.totalDiscount > 0 && (
            <Typography
              sx={{
                ...styles.tag,
                width: "fit-content",
                pl: 1,
                pr: 1,
                borderRadius: "5px",
                ...{ bgcolor: colors.green[500] },
              }}
            >
              Discount {formatCurrency(order.totalDiscount)}
            </Typography>
          )}
          <Typography sx={styles.paymentMethod}>
            {order.paymentMethod}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Typography sx={styles.title}>Order</Typography>
          <Typography sx={styles.orderId}>{"#" + order.id}</Typography>
        </Grid>
        <Grid item xs={16} lg={2.8}>
          <Typography sx={styles.title}>Placed on</Typography>
          <Typography sx={styles.datetime}>
            {getFormatedDate(order.datetime!)}
          </Typography>
          {props.isPrimaryUser && order.reseller.subCompanyName && (
            <Typography sx={styles.datetime}>
              By {order.reseller.firstName + " " + order.reseller.lastName}{" "}
              {order.reseller.subCompanyName}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} lg={1.7}>
          <Typography
            sx={{
              ...styles.tag,
              ...{ bgcolor: paymentStatusColors.get(order.paymentStatus) },
            }}
          >
            {order.paymentStatus}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            sx={styles.viewMore}
            type="button"
            onClick={() => {
              props.clickCard(order);
            }}
          >
            View More
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Box sx={styles.productList}>
        {order.products && order.products.length && (
          <OrdersProductList
            products={order.products}
            styles={styles.product}
            clickViewDigitalKeys={handleClickViewDigitalKeys}
          />
        )}
      </Box>
    </Grid>
  );
};
