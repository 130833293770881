import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { StyleContext } from "../context/StyleContextProvider";
import { EditProfileForm } from "../components/Profile/EditProfileForm";
import { ViewProfile } from "../components/Profile/ViewProfile";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, setProfile } from "../reduxenv/Slices/ProfileSlice";
import { LocalStorage } from "../common/Localstorage";
import { ManageOtherResellers } from "../components/Profile/ManageOtherResellers";
import { Helmet } from "react-helmet-async";
import {
  apiBaseURL,
  appTitle,
  localStorageKeys,
  scrollToTop,
} from "../common/Helper";
import { Wallpaper } from "../components/shared/Wallpaper";
import { CreditStatus } from "../components/Profile/CreditStatus";
import useConfirm from "../hooks/useConfirm";
import { Link } from "react-router-dom";
import { PhotoCamera } from "@mui/icons-material";
import { useUploadProfilePictureMutation } from "../services/ProfileServices";
import { setIsLoading } from "../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../models/ResponseModel";
import { validateFile } from "../common/FileValidation";

const ProfilePage: React.FC = () => {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("profile")
  );
  const isMobile = useMediaQuery("(max-width: 550px)");
  const profile = useSelector(getProfile);
  const [isActiveEditProfile, setIsActiveEditProfile] = useState(false);
  const [isShowOtherReseller, setIsShowOtherReseller] = useState(false);
  const [LogoutAlert, confirmLogout] = useConfirm(
    "Logout",
    "Are you sure do you want to Logout?"
  );
  const dispatch = useDispatch();
  const [uploadImage] = useUploadProfilePictureMutation();

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("profile"));
    scrollToTop();
  }, [isMobile]);

  function handleClickAccordianButton(isEditProfile = true) {
    setIsActiveEditProfile(isEditProfile);
  }

  async function logout() {
    let logoutConfirmed = await confirmLogout();
    if (logoutConfirmed) {
      LocalStorage.clearStorage().then(() => {
        window.location.reload();
      });
    }
  }

  function toggleActiveEditProfile() {
    setIsActiveEditProfile(!isActiveEditProfile);
  }

  function toggleShowManageOtherResellers() {
    setIsShowOtherReseller(!isShowOtherReseller);
  }

  async function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    let file = event.target.files;
    if (file && file.length > 0 && validateFile(file[0].name, true)) {
      dispatch(setIsLoading(true));
      let formData = new FormData();
      formData.append("file", file[0]);
      let response: any = await uploadImage(formData);
      if (response && response.data) {
        let temProfile = { ...profile };
        temProfile.avatar = (response.data as ResponseModel).response;
        dispatch(setProfile(temProfile));
        localStorage.setItem(
          localStorageKeys.profile,
          JSON.stringify(temProfile)
        );
        event.target.files = null;
      }
      dispatch(setIsLoading(false));
    }
  }

  return (
    <>
      <Helmet>
        <title>{appTitle + " - Profile"}</title>
      </Helmet>
      <Header />
      <Grid container spacing={{ xs: 0, lg: 4 }} justifyContent={"center"}>
        <Wallpaper content={profile.isPrimaryUser && <CreditStatus />} />
        <Container>
          <Grid container maxWidth={"xl"} spacing={4} justifyContent={"center"}>
            <Grid item lg={7} xs={12} order={{ xs: 3, lg: 2 }}>
              <Card sx={styles.contentWrapper} variant="outlined">
                <CardContent>
                  <div style={styles.contentButtonWrapper}>
                    <Button
                      fullWidth
                      sx={{
                        ...styles.accordianButton,
                        ...styles.accordianButton.left,
                        ...(!isActiveEditProfile &&
                          styles.accordianButton.selected),
                      }}
                      onClick={() => {
                        handleClickAccordianButton(false);
                      }}
                    >
                      view Profile
                    </Button>
                    <Button
                      fullWidth
                      sx={{
                        ...styles.accordianButton,
                        ...styles.accordianButton.right,
                        ...(isActiveEditProfile &&
                          styles.accordianButton.selected),
                      }}
                      onClick={() => {
                        handleClickAccordianButton();
                      }}
                    >
                      Edit Profile
                    </Button>
                  </div>
                  {isActiveEditProfile ? (
                    <EditProfileForm
                      profile={profile}
                      viewProfile={toggleActiveEditProfile}
                    />
                  ) : (
                    <ViewProfile
                      profile={profile}
                      showEditForm={toggleActiveEditProfile}
                    />
                  )}
                  <Button
                    variant="text"
                    fullWidth
                    sx={styles.logoutButtonMobile}
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              sx={styles.imageContainer}
              xs={12}
              lg={2.3}
              order={{ xs: 2, lg: 3 }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Avatar
                  sx={styles.imageWrapper}
                  variant="square"
                  src={apiBaseURL + profile.avatar}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                    backgroundColor: "white",
                    borderRadius: "50%",
                    padding: 0.5,
                  }}
                >
                  <PhotoCamera />
                  <input
                    hidden
                    onChange={handleImageUpload}
                    accept="image/*"
                    type="file"
                  />
                </IconButton>
              </Box>
              {profile.isPrimaryUser && (
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.manageReseller}
                  onClick={toggleShowManageOtherResellers}
                >
                  Manage other Resellers
                </Button>
              )}
              {profile.isPendingSignatures && (
                <Link to={"pending-signatures"}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    sx={{ mt: 1 }}
                  >
                    Upload Signatures
                  </Button>
                </Link>
              )}
              <Button
                variant="contained"
                fullWidth
                sx={styles.logoutButton}
                onClick={logout}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer />
      <LogoutAlert />
      <Dialog
        open={isShowOtherReseller}
        onClose={toggleShowManageOtherResellers}
        fullWidth
      >
        <ManageOtherResellers />
      </Dialog>
    </>
  );
};

export default ProfilePage;
