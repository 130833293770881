import { ContactdetailsModel } from "../models/ContactDetailsModel";

export const Contactdetails: ContactdetailsModel[] = [
  {
    country: "Australia - Global HQ",
    email: "info@ictdistribution.net",
    phone: "1300121999",
    address:
      "Level 13 Citigroup Centre, 2-26 Park Street, Sydney NSW, Australia 2000",
  },
  {
    country: "Singapore - Regional HQ",
    email: "",
    phone: "",
    address: "68 CIRCULAR ROAD, #02-01 SINGAPORE, (049422)",
  },
  {
    country: "Sri Lanka",
    email: "info@ictdistribution.net",
    phone: "+94 112 590 186",
    address: "No17- 1/1 Castle Lane, Colombo 04 Sri Lanka",
  },
  {
    country: "Maldives",
    email: "info@ictdistribution.net",
    phone: "",
    address: "",
  },
  {
    country: "Bangladesh",
    email: "info@ictdistribution.net",
    phone: "+88 (0) 9677 601 601",
    address:
      "Sekander heights, Flat 6B, 44/14 west panthapath, Dhaka 1205 Bangladesh",
  },
  {
    country: "Cambodia",
    email: "info@ictdistribution.net",
    phone: "+855 23 238 953",
    address:
      "Phnom Penh Center, South Building (F), Room No. 069, Sothearos (03) Blvd., Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, 120101, Cambodia",
  },
  {
    country: "Nepal",
    email: "info@ictdistribution.net",
    phone: "",
    address: "",
  },
  {
    country: "Myanmar",
    email: "",
    phone: "",
    address:
      "Room 6, Building 4, Myanmar ICT Park, Hlaing Universities' Campus, Yangoon, 11051 Myanmar",
  },
];
