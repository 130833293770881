import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppColors } from "../../common/AppColors";
import { snakeToCamel } from "../../common/Helper";
import { useGetAllCategoriesQuery } from "../../services/CategoriesServices";
import { CategoriesLoader } from "../Loaders/CategoriesLoader";
import { Link } from "react-router-dom";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CategoryModel } from "../../models/CategoryModel";

const CategoryList: React.FC = () => {
  const { data: categoriesResponse } = useGetAllCategoriesQuery();
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  useEffect(() => {
    if (categoriesResponse && categoriesResponse.status === "success") {
      let tempCategories = snakeToCamel(
        categoriesResponse.response
      ) as CategoryModel[];
      console.log(tempCategories);
      
      setCategories(tempCategories);
    }
  }, [categoriesResponse]);

  return (
    <Box
      border={"1px solid " + AppColors.MainColor}
      borderRadius={"4px"}
      mt={{ xs: 2, lg: 0 }}
      ml={{ xs: 0, lg: 2 }}
      p={1}
      height={"max-content"}
    >
      <Typography
        color={AppColors.MainColor}
        variant="h5"
        fontWeight={"bold"}
        mt={2}
        textAlign={"center"}
        fontSize={"medium"}
      >
        PRODUCT CATEGORIES
      </Typography>
      <TreeView
        defaultExpandIcon={<ExpandMore />}
        defaultCollapseIcon={<ExpandLess />}
      >
        {categories.length > 0 ? (
          categories
            .filter((x) => x.parentId === 0)
            .map((sub) => {
              var level3categories = categories.filter(
                (e) => e.parentId == sub.id
              );
              return (
                <>
                  {level3categories.length > 0 ? (
                    <TreeItem
                      key={sub.id}
                      sx={{
                        borderRadius: "5px",
                        lineHeight: 1.5,
                        color: AppColors.Blue,
                      }}
                      nodeId={sub.id.toString()}
                      label={
                        <Link
                          to={"/category-products/" + sub.id}
                          state={sub.name}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            p={0.5}
                            variant="subtitle1"
                            color={AppColors.Blue}
                          >
                            {sub.name}
                          </Typography>
                        </Link>
                      }
                    >
                      <TreeView>
                        {level3categories.map((lev3) => (
                          <TreeItem
                            key={lev3.id}
                            sx={{
                              borderRadius: "5px",
                              lineHeight: 1.5,
                              color: AppColors.Blue,
                            }}
                            nodeId={sub.id.toString()}
                            label={
                              <Link
                                to={"/category-products/" + lev3.id}
                                state={lev3.name}
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <Typography
                                  p={0.5}
                                  variant="subtitle1"
                                  color={AppColors.Blue}
                                >
                                  {lev3.name}
                                </Typography>
                              </Link>
                            }
                          />
                        ))}
                      </TreeView>
                    </TreeItem>
                  ) : (
                    <TreeItem
                      key={sub.id}
                      sx={{
                        borderRadius: "5px",
                        lineHeight: 1.5,
                        color: AppColors.Blue,
                      }}
                      nodeId={sub.id.toString()}
                      label={
                        <Link
                          to={"/category-products/" + sub.id}
                          state={sub.name}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            p={0.5}
                            variant="subtitle1"
                            color={AppColors.Blue}
                          >
                            {sub.name}
                          </Typography>
                        </Link>
                      }
                    />
                  )}
                </>
              );
            })
        ) : (
          <CategoriesLoader />
        )}
      </TreeView>
    </Box>
  );
};

export default CategoryList;
